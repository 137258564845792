export function normalizeCustomAttributes(
	customAttributes?: { key: string; value?: string | number }[]
) {
	let result: Record<string, string | undefined> = {}

	if (customAttributes) {
		customAttributes.forEach((pair) => {
			try {
				const data = JSON.parse(pair.key)
				if (typeof data === 'object') {
					result = { ...result, ...data }
				}
			} catch (e) {}
			result[pair.key] = String(pair.value ?? '')
		})
	}

	return result
}

export function normalizeCustomAttributesWithNameKey(
	customAttributes?: { name: string; value?: string | number }[]
) {
	let result: Record<string, string | undefined> = {}

	if (customAttributes) {
		customAttributes.forEach((pair) => {
			try {
				const data = JSON.parse(pair.name)
				if (typeof data === 'object') {
					result = { ...result, ...data }
				}
			} catch (e) {}
			result[pair.name] = String(pair.value ?? '')
		})
	}

	return result
}
