import * as z from 'zod'
import { normalizeCustomAttributes } from '../shopify/normalizeCustomAttributes'

type ShopifyPossibleKeyname = 'name' | 'key'

type Attributes = { key?: string; name?: string; value?: string }[]

type Customization = {
	version: number
	hidden?: Record<string, unknown>
	visible?: Record<string, string | undefined>
}

const CUSTOMIZATION_VERSION = 1

function serializeCustomization(data: Record<string, unknown>): string {
	return JSON.stringify(removeEmpty({ customizationVersion: CUSTOMIZATION_VERSION, data }))
}

const hidden = z.object({
	customizationVersion: z.number(),
	data: z.record(z.any()),
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function removeEmpty(obj: Record<string, any>): Record<string, any> {
	return Object.fromEntries(
		Object.entries(obj)
			.filter(([_, v]) => typeof v !== 'undefined')
			.map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
	)
}

export function unserializeCustomization(data: string) {
	const parsed = JSON.parse(data)
	return hidden.parse(parsed).data
}

export function createCustomization(
	visible: Record<string, string>,
	hidden?: Record<string, unknown>
): Customization {
	return {
		version: CUSTOMIZATION_VERSION,
		visible,
		hidden,
	}
}

export function customizationToAttributes(data: Customization, keyname: ShopifyPossibleKeyname) {
	const hidden = data.hidden ? serializeCustomization(data.hidden) : null

	const attributes: Attributes = []

	if (hidden) {
		attributes.push({ [keyname]: hidden, value: '' })
	}

	if (data.visible) {
		const keys = Object.keys(data.visible) as (keyof typeof data.visible)[]

		keys.forEach((key) => {
			if (data.visible && key in data.visible) {
				attributes.push({ [keyname]: key, value: data.visible[key] })
			}
		})
	}

	return attributes
}

export function attributesToCustomization(attributes: Attributes) {
	const c: Customization = {
		version: CUSTOMIZATION_VERSION,
		visible: {},
	}

	attributes.forEach((attr) => {
		const key = attr.key ?? attr.name
		if (key && key.substr(0, 1) === '{') {
			try {
				const hidden = unserializeCustomization(key)
				c.hidden = hidden
				return
			} catch (e) {}
		}

		if (c.visible && key) {
			c.visible[key] = attr.value
		}
	})

	return c
}

const clotinoCustomization = z.object({
	visible: z.record(z.string()),
	hidden: z.object({
		text: z.string(),
		icon: z.object({
			id: z.string(),
			key: z.string(),
			unique: z.string(),
			title: z.string(),
			image: z.object({
				url: z.string(),
			}),
		}),
	}),
})

export function parseClotinoCustomization(data: unknown) {
	return clotinoCustomization.parse(data)
}

export function unifiedParseCustomization(
	data: { key: string; value: string | number | null | undefined }[]
) {
	const normalized = data.map((item) => ({
		key: String(item.key ?? ''),
		value: String(item.value ?? ''),
	}))

	try {
		const v1 = parseClotinoCustomization(attributesToCustomization(normalized))
		return {
			icon: v1.hidden.icon.key,
			text: v1.hidden.text,
		}
	} catch (e) {}
	try {
		const legacy = normalizeCustomAttributes(normalized)
		if ('icon' in legacy && 'text' in legacy) {
			return {
				icon: legacy.icon,
				text: legacy.text,
			}
		}
	} catch (e) {}

	return null
}

export function nameToKeyAttributes(
	data: ((
		| {
				key: string | number | null
		  }
		| {
				name: string | number | null
		  }
	) & { value?: string | number | null })[]
): { key: string; value: string }[] {
	return data.map((item) => {
		const key = ('key' in item ? item.key : item.name) ?? ''

		return { key: String(key ?? ''), value: String(item.value ?? '') }
	})
}
